<template>
  <div class="ele-body">
    <el-card shadow="never">
      <div>
        <div class="d-flexspabet mb-20">
          <span>已开放省市县列表</span>
          <el-button @click="addCity" class="ele-btn-icon addbtn" size="small">
            新增省市县
          </el-button>
        </div>
        <div class="cityHeader">
          <div>地区</div>
          <div>地区编号</div>
          <div>父级编号</div>
          <div>状态</div>
          <div>操作</div>
        </div>
        <div class="cityContentBox" v-for="option in provinceList" :key='option.pid'>
          <div class="cityContent" @click="handlePro(option.pid)">
            <div><i :class="option.icon"></i> {{ option.name }}({{ option.open_city_num }})</div>
            <div>{{ option.pid }}</div>
            <div>{{ option.father_id }}</div>
            <div class="cityStatus"> {{ option.status == 1 ? '开放' : '关闭' }}</div>
            <el-link @click.stop="order_status_update(option.pid, option.name, 'province', option.pid)" :underline="false">
              关闭
            </el-link>
          </div>
          <div v-if="option.isShow">
            <div v-for="(options, index) in cityList" :key='options.cid'>
              <div class="cityContent" @click="handleCity(options.cid)">
                <div>
                  <i :class="options.icon"></i>
                  {{ options.name }}
                  ({{ cityListLength[index] }})
                </div>
                <div> {{ options.cid }}</div>
                <div> {{ options.father_id }}</div>
                <div class="cityStatus" style="margin-left:30px"> {{ options.status == 1 ? '开放' : '关闭' }}</div>
                <el-link @click.stop="order_status_update(options.cid, options.name, 'city', options.pid)"
                  :underline="false">关闭
                </el-link>
              </div>
              <div v-if="options.isShowarea">
                <div class="cityContent" v-for="optionss in countryList" :key='optionss.aid' style="margin: 10px 40px">
                  <div><i class="el-icon-arrow-right"></i> {{ optionss.name }}</div>
                  <div> {{ optionss.aid }}</div>
                  <div style="margin-left:30px"> {{ optionss.father_id }}</div>
                  <div class="cityStatus" style="margin-left:70px"> {{ options.status == 1 ? '开放' : '关闭' }}
                  </div>
                  <el-link @click="open_setting_view(optionss.aid)" :underline="false">设置</el-link>
                  <el-link @click.stop="order_status_update(optionss.aid, optionss.name, 'area', optionss.cid)"
                    :underline="false">关闭
                  </el-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 已开放省市县列表 -->
    <el-dialog v-dialogDrag title="新增省市区" :visible.sync="dialogTableVisibleAddCity" width="1200px"
      :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <div class="layui-table cityHeader1">
          <td>省</td>
          <td>城市</td>
          <td>县/区</td>
          <td>ID</td>
          <td>运营商</td>
          <td>状态</td>
          <td>操作</td>
        </div>
        <div class="cityContentBox" v-for="option in provinceListAll" :key='option.pid'>
          <div class="bgwhite" @click="handleProAll(option.pid)">
            <div>{{ option.name }}</div>
            <div>--</div>
            <div>--</div>
            <div>{{ option.pid }}</div>
            <div>--</div>
            <div :class="option.status == 1 ? 'cityStatus' : ''"> {{ option.status == 1 ? '开放' : '关闭' }}</div>
            <div>
              <el-link @click.stop="order_status_update1(option.pid, 0, 0, 1, 'province')" v-if="option.status == 1"
                :underline="false" class="btn_red">关闭
              </el-link>
              <el-link @click.stop="order_status_update1(option.pid, 0, 0, 2, 'province')" v-else :underline="false"
                class="btn_green">开放
              </el-link>
            </div>
          </div>
          <div v-if="option.isShowCity">
            <div v-for="options in cityListAll" :key='options.cid' @click="handleCityAll(options.cid)">
              <div class=" bgwhite">
                <div> --</div>
                <div>{{ options.name }}</div>
                <div>--</div>
                <div>{{ options.cid }}</div>
                <div>--</div>
                <div :class="options.status == 1 ? 'cityStatus' : ''"> {{ options.status == 1 ? '开放' : '关闭' }}</div>
                <div>
                  <el-link @click.stop="order_status_update1(option.pid, options.cid, 0, 1, 'city')" v-if="options.status == 1"
                    :underline="false" class="btn_red">关闭
                  </el-link>
                  <el-link @click.stop="order_status_update1(option.pid, options.cid, 0, 2, 'city')" v-else :underline="false"
                    class="btn_green">开放
                  </el-link>
                </div>
              </div>
              <div v-if="options.isShow">
                <div v-for="optionss in countryListAll" :key='optionss.aid'>
                  <div class=" bgwhite">
                    <div> --</div>
                    <div> --</div>
                    <div>{{ optionss.name }}</div>
                    <div> {{ optionss.aid }}</div>
                    <div> --</div>
                    <div :class="optionss.status == 1 ? 'cityStatus' : ''"> {{ optionss.status == 1 ? '开放' : '关闭' }}
                    </div>
                    <div>
                      <el-link @click.stop="order_status_update1(option.pid, options.cid, optionss.aid, 1, 'area')"
                        v-if="optionss.status == 1" :underline="false" class="btn_red">关闭
                      </el-link>
                      <el-link @click.stop="order_status_update1(option.pid, options.cid, optionss.aid, 2, 'area')" v-else
                        :underline="false" class="btn_green">开放
                      </el-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>

    </el-dialog>








    <!-- 添加弹窗 -->
    <el-dialog v-dialogDrag :title="dlogTitle" :visible.sync="showEdit" :destroy-on-close="true" :lock-scroll="false"
      width="60%">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="150px">
          <el-form-item label="标题：" prop="title">
            <el-input type="text" placeholder="请输入关闭原因通知标题,不得多于16字" v-model="editForm.title" class="input316">
            </el-input>
          </el-form-item>

          <el-form-item label="内容：" prop="content">
            <el-input type="textarea" :rows="3" placeholder="请输入关闭原因通知内容,不得多于100字" v-model="editForm.content">
            </el-input>
          </el-form-item>

          <el-form-item class="block" label="关闭时间：">
            <el-date-picker v-model="timeVal" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
              start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00']">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="关闭类型：" prop="close_type">
            <el-radio-group v-model="editForm.close_type">
              <el-radio :label="1">平台维护</el-radio>
              <el-radio :label="2">疫情防控</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="关闭客户端：" prop="client">
            <el-radio-group v-model="editForm.client">
              <el-radio :label="1">全平台</el-radio>
              <el-radio :label="2">用户（APP、小程序）</el-radio>
              <el-radio :label="3">司机（APP）</el-radio>
              <el-radio :label="4">电召</el-radio>
            </el-radio-group>
          </el-form-item>

        </el-form>
      </el-card>
      <div slot="footer" v-if="!editForm.id">
        <el-button @click="showEdit = false">取消</el-button>
        <el-button type="primary" @click="save">发布</el-button>
      </div>
      <div slot="footer" v-if="editForm.id">
        <el-button @click="(showEdit = false) && (showEditData = false)">取消</el-button>
        <el-button type="primary" @click="update">发布</el-button>
      </div>
    </el-dialog>


    <!--功能开放设置-->
    <el-dialog v-dialogDrag title="模块开关" :visible.sync="dialogTableVisibleEdit" :destroy-on-close="true"
      :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="checkForm" ref="checkForm" label-width="170px">
          <el-form-item label="区县编号：" prop="id">
            <el-input v-model="checkForm.id" placeholder="" clearable disabled />
          </el-form-item>
          <el-form-item label="区县名称：" prop="name">
            <el-input v-model="checkForm.name" placeholder="" clearable disabled />
          </el-form-item>

          <!-- <div v-if="checkForm.chuzu==false">
            <el-form-item label="巡游出租模块:" prop="chuzu" class="item_switch">
              <el-switch v-model="checkForm.chuzu" @click="dialogTableVisible = true" modal="false"
                @change="off(checkForm.chuzu)" active-text="开启" inactive-text="关闭" checked>
              </el-switch> -->
          <!-- <el-popover placement="right" width="400" style="height: 200px;" trigger="click">
              <el-table :data="gridData">
              
                <el-table-column width="100" property="name" label="关闭标题"></el-table-column>
                <el-table-column width="300" property="address" label="关闭内容"></el-table-column>
                <el-table-column width="150" property="date" label="关闭时间"></el-table-column>
                <el-table-column width="300" property="address" label="关闭类型"></el-table-column>
                <el-table-column width="300" property="address" label="关闭客户端"></el-table-column>
              </el-table>
              <el-button slot="reference"  style="margin-left: 40px;color:#409EFF ;text-decoration:underline">查看原因</el-button>
            </el-popover> -->

          <!-- <div style="margin-left: 40px;" @click="dialogTableVisible = ture"> <span
                  style="color:#409EFF ;text-decoration:underline">查看原因</span></div> -->
          <!-- </el-form-item> -->









          <!-- <div v-else="checkForm.chuzu==true"> -->
          <!-- {{checkForm.chuzu}} -->
          <el-form-item label="巡游出租模块:" prop="chuzu" class="item_switch title-box">
            <div class="title-item" @click="see" v-if="closeText">查看关闭原因 ></div>
            <el-switch v-model="checkForm.chuzu" @click="dialogFormVisible = false" @change="off(checkForm.chuzu)"
              active-text="开启" inactive-text="关闭" checked>
            </el-switch>
          </el-form-item>
          <!-- </div> -->
          <!-- <span v-if="this.show"></span> -->
          <!-- <el-dialog title="收货地址" :visible.sync="dialogTableVisible">
            <el-table :data="gridData">
              <el-table-column property="date" label="日期" width="150"></el-table-column>
              <el-table-column property="name" label="姓名" width="200"></el-table-column>
              <el-table-column property="address" label="地址"></el-table-column>
            </el-table>
          </el-dialog> -->

          <el-form-item label="合乘模块:" prop="pinche" class="item_switch">
            <el-switch v-model="checkForm.pinche" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="代驾功能:" prop="daijia" class="item_switch">
            <el-switch v-model="checkForm.daijia" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="跑腿功能:" prop="paotui" class="item_switch">
            <el-switch v-model="checkForm.paotui" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="超市功能:" prop="chaoshi" class="item_switch">
            <el-switch v-model="checkForm.chaoshi" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="叫餐功能:" prop="jiaocan" class="item_switch">
            <el-switch v-model="checkForm.jiaocan" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="线圈功能:" prop="xianquan" class="item_switch">
            <el-switch v-model="checkForm.xianquan" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>

          <el-form-item label="头条功能:" prop="toutiao" class="item_switch">
            <el-switch v-model="checkForm.toutiao" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="景点功能:" prop="jingdian" class="item_switch">
            <el-switch v-model="checkForm.jingdian" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="语音通话模块:" prop="yuyin" class="item_switch">
            <el-switch v-model="checkForm.yuyin" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="线下支付模块:" prop="yuyin" class="item_switch">
            <el-switch @change="changeStatus($event, 1)" v-model="checkForm.payments_offline" active-text="开启"
              inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="闪电对讲模块:" prop="duijiang" class="item_switch">
            <el-switch v-model="checkForm.duijiang" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="微信支付模块:" prop="payment_wxpay" class="item_switch">
            <el-switch @change="changeStatus($event, 2)" v-model="checkForm.payment_wxpay" active-text="开启"
              inactive-text="关闭">
            </el-switch>
          </el-form-item>

          <el-form-item label="95128电召模块:" prop="car_hotline" class="item_switch">
            <el-switch @change="changeholine($event, checkForm.car_hotline)" v-model="checkForm.car_hotline"
              active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>

          <!-- <el-form-item label="支付宝支付开关:" prop="payment_alipay" class="item_switch">
            <el-switch @change="changeStatus($event,3)" v-model="checkForm.payment_alipay" active-text="开启"
                       inactive-text="关闭">
            </el-switch>
          </el-form-item> -->
          <el-form-item label="车牌隐私模块:" prop="carnumber_show" class="item_switch">
            <el-switch @change="changeCarNUmber($event, checkForm.carnumber_show)" v-model="checkForm.carnumber_show"
              active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item v-show="isShowNumber" label="热线电话:" prop="hotline_number" class="item_switch">
            <el-input v-model="checkForm.hotline_number">
            </el-input>
          </el-form-item>
          <!-- 新增模块    //发现模块  //代驾模块 //定制客运模块 //外卖模块 //特种模块// 广告模块 -->
          <!-- <el-form-item label="发现模块:" prop="ordernum" class="item_switch">
            <el-switch v-model="checkForm.ordernum" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="代驾模块:" prop="ordernum" class="item_switch">
            <el-switch v-model="checkForm.ordernum" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="定制客运模块:" prop="ordernum" class="item_switch">
            <el-switch v-model="checkForm.ordernum" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="外卖模块:" prop="ordernum" class="item_switch">
            <el-switch v-model="checkForm.ordernum" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="特种模块:" prop="ordernum" class="item_switch">
            <el-switch v-model="checkForm.ordernum" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="广告模块:" prop="ordernum" class="item_switch">
            <el-switch v-model="checkForm.ordernum" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item> -->

          <!-- <div v-if="checkForm.hotline_number!=''"> -->
          <el-form-item label="平台总订单模块:" prop="ordernum" class="item_switch">
            <el-switch v-model="checkForm.ordernum" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="区域订单模块:" prop="quyu_ordernum" class="item_switch">
            <el-switch v-model="checkForm.quyu_ordernum" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          
          <el-form-item label="App拨打电话模块:" prop="is_phone" class="item_switch">
            <el-switch v-model="checkForm.is_phone" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          
          <el-form-item label="人脸识别:" prop="face_authentication" class="item_switch">
            <el-switch v-model="checkForm.face_authentication" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          

          <el-form-item label="接单号码验证:" prop="check_phone" class="item_switch">
            <el-switch v-model="checkForm.check_phone" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          
          <el-form-item label="约车模块:" prop="booking_taxi" class="item_switch">
            <el-switch v-model="checkForm.booking_taxi" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          
          
          



          <!-- <el-form-item label="附近订单导航距离:" prop="navigation_distance" class="item_switch">
            <el-switch v-model="checkForm.navigation_distance" active-text="开启" inactive-text="关闭">
            </el-switch>
            </el-form-item> -->


          <el-form-item label="云闪付支付模块:" prop="payment_unionpay" class="item_switch">
            <el-switch @change="changeStatus($event, 5)" v-model="checkForm.payment_unionpay" active-text="开启"
                       inactive-text="关闭">
            </el-switch>
          </el-form-item>
        <el-form-item label="助老叫车:" prop="is_old" class="item_switch">
            <el-switch v-model="checkForm.is_old" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="子女代付:" prop="payment_childrenpay" class="item_switch">
            <el-switch v-model="checkForm.payment_childrenpay" active-text="开启" inactive-text="关闭">
            </el-switch>
          </el-form-item>

          
          <!-- </div> -->
          <!-- <div v-else>这是为False的状态</div> -->

        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="dialogTableVisibleEdit = false">取消</el-button>
        <el-button type="primary" @click="open_setting">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  off
} from 'process'

export default {
  name: 'SysRole',
  data() {
    var checktitle = (rule, value, callback) => {
      // var reg = new RegExp("^[\u4e00-\u9fa5|0-9|a-z|\,|\:|\~|\.|，|。]{1,16}$", "g");
      // if (!reg.test(value)) {
      //   callback(new Error('请输入关闭原因通知标题,不得多于16字'));
      // } else {
      //   callback();
      // }
    };
    var checktxt = (rule, value, callback) => {
      // var reg = new RegExp("^[\u4e00-\u9fa5|0-9|a-z|\,|\:|\~|\.|，|。]{1,100}$", "g");
      // if (!reg.test(value)) {
      //   callback(new Error('请输入关闭原因,不得多于100字'));
      // } else {
      //   callback();
      // }
    };
    return {
      dlogTitle: '巡游出租模块关闭的原因',
      provArr: [],
      cityArr: [],
      districtArr: [],
      choose: [], // 表格选中数据
      showEdit: false, // 是否显示表单弹窗
      showEditData: false, // 编辑内容信息
      timeVal: [],
      isCanClose: true,


      close_type: 1,
      client: 1,
      closeText: '设置',
      hasForeMsg: false,


      gridData: '',
      show: '',
      pid: '',
      dialogTableVisibleAddCity: false,
      dialogTableVisibleEdit: false,
      cityListAll: [],
      provinceListAll: [],
      countryListAll: [],
      provinceList: [],
      cityList: [],
      countryList: [],
      checkForm: {},
      value: 0,
      cityListLength: [],
      isShowNumber: false,
      isShowarea: false,
      isShowCarNumber: false,
      num: 0,
      numid: '',
      numcid: '',
      dialogTableVisible: false,
      dialogTableVisibles: false,
      dialogFormVisible: false,
      editForm: {},
      form: {
        title: '',
        text: '',
      },
      formLabelWidth: '120px',
      editRules: { // 表单验证规则
        title: [
          {
            required: true,
            message: '请输入标题名称',
            trigger: 'blur'
          },

          {
            validator: checktitle, trigger: 'blur'
          }
        ],
        content: [{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        },
        {

          validator: checktxt, trigger: 'blur'
        }
        ],
        close_type: [{
          required: true,
          message: '请选择关闭类型',
          trigger: "change"
        }],
        client: [{
          required: true,
          message: '请选择关闭客户端',
          trigger: "change"
        }],
      }
    }

  },
  created() {
    const loading = this.$loading({
      lock: true
    })
    let param = {
      status: 1
    }
    this.$http.post('/admin/open_province_list', param).then(res => {
      loading.close()
      let data = JSON.parse(res.data)
      data.forEach(function (item) {
        item.icon = 'el-icon-arrow-right'
        item.isShow = false
      })
      this.provinceList = data
    })
  },
  methods: {
    getForce() {
      this.editForm = {};
      this.$http.post('/ForceMsg/getForce', {
        'aid': this.checkForm.id
      }).then(res => {
        //console.log('8888rrr88888', res);
        if (res.data.code == 1) {
          this.editForm = res.data.data.result;
          this.timeVal[0] = this.editForm.cn_start_time;
          this.timeVal[1] = this.editForm.cn_expires_time;
          this.closeText = true;
          this.hasForeMsg = res.data.data.isExpir == 1 ? true : false;
          this.checkForm.chuzu = !this.hasForeMsg;

        } else {
          this.hasForeMsg = false;
          this.closeText = false;
        }
      });

    },
    update() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          if (this.timeVal.length == 2) {
            this.editForm.start_time = this.timeVal[0]
            this.editForm.expires_time = this.timeVal[1]
          } else {
            this.$message.error('请选择关闭时间');
            return false;
          }

          this.editForm.aid = this.checkForm.id
          const loading = this.$loading({
            lock: true
          });

          this.$http.post('/ForceMsg/updateForce', this.editForm).then(res => {
            loading.close();
            //console.log('99999555', res);

            if (res.data.code == 1) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.showEdit = false;
              this.getForce();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });

    },
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          if (this.timeVal.length == 2) {
            this.editForm.start_time = this.timeVal[0]
            this.editForm.expires_time = this.timeVal[1]
          } else {
            this.$message.error('请选择关闭时间');
            return false;
          }

          this.editForm.aid = this.checkForm.id
          const loading = this.$loading({
            lock: true
          });

          this.$http.post('/ForceMsg/addForce', this.editForm).then(res => {
            loading.close();

            //console.log('66677775555', res);

            if (res.data.code == 1) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.showEdit = false;
              this.getForce();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });

    },
    see() {
      this.showEdit = !this.showEdit

    },
    onAddUser(e) {
      this.form.end_time = this.form.start_time[1]
      this.form.start_time = this.form.start_time[0]
      this.form.city = e
      this.$http.post('/city/close_causes', this.form).then(res => {

        if (res.data.success == true) {
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          this.dialogFormVisible = false
        } else {
          this.$message({
            type: 'error',
            message: '添加失败'
          })
        }

      })

    },
    off(e) {

      if (!e) {
        //console.log('000000000000000000000000000000000000');
        if ((false == this.hasForeMsg)) {
          this.$alert('关闭当前模块需要先设置关闭原因！', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              // this.checkForm.chuzu = true;
              // return false;

              this.see();

            }
          })
        }
      } else {
        //开启,删除强制消息
        this.$http.post('/ForceMsg/delForce', {
          aid: this.checkForm.id
        }).then(res => {
          this.closeText = false;

        })


        //console.log('1111111111111');
      }


      this.dialogFormVisible = 'ture'
    },
    changeholine(e, type) {
      this.isShowNumber = type
    },
    changeCarNUmber(e, type) {
      this.isShowCarNumber = type
    },
    changeStatus(e, type) {
      // //console.log(e)
      // type：1 点击线下支付
      // type：2 微信支付	payment_wxpay
      // type：3 支付宝支付 payment_alipay
      if (!this.checkForm.payments_offline && !this.checkForm.payment_wxpay && !this.checkForm.payment_unionpay) {
        this.$alert('线下支付丶微信支付、云闪付支付至少保留一种支付方式！', '提示', {
          confirmButtonText: '确定',
          callback: action => {

          }
        })
        if (type == 1) {
          this.checkForm.payments_offline = true
        } else if (type == 2) {
          this.checkForm.payment_wxpay = true
        } else if (type == 5) {
          this.checkForm.payment_unionpay = true
        } else {
          this.checkForm.payment_alipay = true
        }
      }
    },
    addCity() {
      this.dialogTableVisibleAddCity = true
      if (this.num == 0) {
        this.num = 1
        this.$http.post('/admin/open_province_list').then(res => {
          let data = JSON.parse(res.data)
          data.forEach(function (item) {
            item.icon = 'el-icon-arrow-right'
            item.isShowCity = false
          })
          this.provinceListAll = data
        })
      }
    },
    handleProAll(pid) {
      this.provinceListAll.forEach(function (item) {
        if (item.pid == pid) {
          item.isShowCity = !item.isShowCity
        } else {
          item.isShowCity = false
        }
      })
      let param = {
        pid: pid
      }
      this.$http.post('/admin/open_city_list', param).then(res => {
        let data = JSON.parse(res.data)[0]
        data.forEach(function (item) {
          item.icon = 'el-icon-arrow-right'
          item.isShow = false
        })
        this.cityListAll = data
      })
    },
    handleCityAll(id) {
      let that = this
      this.cityListAll.forEach(function (item) {
        if (item.cid == id) {
          that.countryListAll = item.area_list
          item.isShow = !item.isShow
          if (item.isShow) {
            item.icon = 'el-icon-arrow-down'
          } else {
            item.icon = 'el-icon-arrow-right'
          }
        } else {
          item.isShow = false
          item.icon = 'el-icon-arrow-right'
        }
      })
    },
    //单击省份
    handlePro(id) {
      if (id) {
        this.numid = id
      } else {
        id = this.numid
      }

      let that = this
      let param = {
        pid: id,
        status: 1
      }
      this.$http.post('/admin/open_city_list', param).then(res => {
        let data = JSON.parse(res.data)
        data[0].forEach(function (item) {
          item.icon = 'el-icon-arrow-right'
          item.isShow = false
        })
        that.cityList = data[0]
        if (data[0].length != 0) {
          that.provinceList.forEach(function (item) {
            if (item.pid == id) {
              item.isShow = !item.isShow
              that.pid = id
              if (item.isShow) {
                item.icon = 'el-icon-arrow-down'
              } else {
                item.icon = 'el-icon-arrow-right'
              }
            } else {
              item.isShow = false
              item.icon = 'el-icon-arrow-right'
            }
          })
        }
        that.provinceList = this.provinceList
        that.cityListLength = []
        this.cityList.forEach(function (item) {
          that.cityListLength.push(item.area_list.length)
        })
      })
    },
    handleCity(id) {
      if (id) {
        this.numcid = id
      } else {
        id = this.numcid
      }
      //				alert(id)
      let that = this
      this.cityList.forEach(function (item) {
        if (item.cid == id) {
          that.countryList = item.area_list

          //						item.isShowarea = !item.isShowarea
          item.isShowarea = true
          if (item.isShowarea) {
            item.icon = 'el-icon-arrow-down'
          } else {
            item.icon = 'el-icon-arrow-right'
          }
        } else {
          item.isShowarea = false
          item.icon = 'el-icon-arrow-right'
        }
      })
    },
    order_status_update(id, name, type, nid) {
      var pid = 0
      var cid = 0
      var aid = 0
      if (type == 'province') {
        pid = id
      }
      if (type == 'city') {
        cid = id
      }
      if (type == 'area') {
        aid = id
      }
      this.$confirm('确认关闭' + name + '该地区?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true
        })
        this.$http.post('admin/update_city_status', {
          type: type,
          pid: pid,
          cid: cid,
          aid: aid
        }).then(res => {
          loading.close()
          if (res.data.code === 0) {
            this.$http.post('/admin/open_province_list', {
              status: 1
            }).then(res => {
              let data = JSON.parse(res.data)
              data.forEach(function (item) {
                item.icon = 'el-icon-arrow-right'
                item.isShow = false
                item.isShowarea = false
              })
              this.provinceList = data
              this.handlePro(nid, type, this.numcid)
              if (type == 'area') {
                //									alert(123);
                this.handleCity(type, this.numcid)
              }
            })
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            // this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg)
          }
        }).catch(e => {
          loading.close()
          this.$message.error(e.message)
        })
      }).catch(() => 0)
    },
    openthird(pid, cid) {

      let param = {
        pid: pid
      };
      let that = this;
      this.$http.post('/admin/open_city_list', param).then(res => {
        let data = JSON.parse(res.data)[0]
        data.forEach(function (item) {
          item.icon = 'el-icon-arrow-right';
          item.isShow = false;
          //console.log(cid == item.cid, cid, item.cid)
          if (cid == item.cid) {
            item.isShow = true;

            that.countryListAll = item.area_list
          }

        })
        this.cityListAll = data
        // //console.log(data)
      })
    },
    order_status_update1(pid, cid, aid, status, type) {
      let that = this;
      if (status == 2) {
        this.$confirm('确定开放该地区?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('admin/update_city_status', {
            type: type,
            status: 1,
            pid: pid,
            cid: cid,
            aid: aid
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              // this.$http.post('/admin/open_province_list', {status: 1}).then(res => {
              // 	let data = JSON.parse(res.data)
              // 	data.forEach(function(item) {
              // 		item.icon = 'el-icon-arrow-right'
              // 		item.isShow = false
              // 	})
              // 	this.provinceList = data
              // 	setTimeout(this.openthird(pid)
              // 		, 3000 )
              //
              // })
              this.$http.post('/admin/open_province_list').then(res => {
                let data = JSON.parse(res.data)
                data.forEach(function (item) {
                  item.icon = 'el-icon-arrow-right'
                  item.isShowCity = false
                  if (item.pid == pid) {
                    item.isShowCity = true
                  }
                })
                this.provinceListAll = data
                setTimeout(this.openthird(pid, cid), 3000)
              })
              //								this.addCity()

              this.$message({
                type: 'success',
                message: res.data.msg
              })

            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        }).catch(() => 0)
      } else {
        this.$confirm('确定关闭该地区?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('admin/update_city_status', {
            type: type,
            status: 2,
            pid: pid,
            cid: cid,
            aid: aid
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {

              this.$http.post('/admin/open_province_list').then(res => {
                let data = JSON.parse(res.data)
                data.forEach(function (item) {
                  item.icon = 'el-icon-arrow-right'
                  item.isShowCity = false
                  if (item.pid == pid) {
                    item.isShowCity = true
                  }
                })
                this.provinceListAll = data
              })
              let param = {
                pid: pid
              }
              this.$http.post('/admin/open_city_list', param).then(res => {
                let data = JSON.parse(res.data)[0]
                data.forEach(function (item) {
                  item.icon = 'el-icon-arrow-right'
                  item.isShow = false
                  //console.log(cid == item.cid, cid, item.cid)
                  if (item.cid == cid) {
                    item.isShow = true;
                    that.countryListAll = item.area_list
                  }
                })
                // //console.log(data)
                this.cityListAll = data
              })

              this.$message({
                type: 'success',
                message: res.data.msg
              })
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        }).catch(() => 0)
      }
    },
    open_setting_view(aid) {
      this.$http.post('/city/setting_status', {
        aid: aid
      }).then(res => {
        this.dialogTableVisibleEdit = true
        if (res.data.code === 0) {
          //console.log('kkkkfkfkfk', res.data.data);
          this.checkForm = res.data.data
          this.getForce();
          if (this.checkForm.chuzu == 1) {
            //控制表单
            //console.log(111)
            // this.checkForm.chuzu = 0
            this.dialogFormVisible = true
            this.checkForm.chuzu = true
          } else {
            //console.log(22)
            // this.checkForm.chuzu = 1
            this.dialogFormVisible = false
            this.checkForm.chuzu = false
          }
          if (this.checkForm.pinche == 1) {
            this.checkForm.pinche = true
          } else {
            this.checkForm.pinche = false
          }
          if (this.checkForm.daijia == 1) {
            this.checkForm.daijia = true
          } else {
            this.checkForm.daijia = false
          }
          if (this.checkForm.paotui == 1) {
            this.checkForm.paotui = true
          } else {
            this.checkForm.paotui = false
          }
          if (this.checkForm.chaoshi == 1) {
            this.checkForm.chaoshi = true
          } else {
            this.checkForm.chaoshi = false
          }
          if (this.checkForm.jiaocan == 1) {
            this.checkForm.jiaocan = true
          } else {
            this.checkForm.jiaocan = false
          }
          if (this.checkForm.xianquan == 1) {
            this.checkForm.xianquan = true
          } else {
            this.checkForm.xianquan = false
          }
          if (this.checkForm.toutiao == 1) {
            this.checkForm.toutiao = true
          } else {
            this.checkForm.toutiao = false
          }
          if (this.checkForm.jingdian == 1) {
            this.checkForm.jingdian = true
          } else {
            this.checkForm.jingdian = false
          }
          if (this.checkForm.yuyin == 1) {
            this.checkForm.yuyin = true
          } else {
            this.checkForm.yuyin = false
          }
          if (this.checkForm.duijiang == 1) {
            this.checkForm.duijiang = true
          } else {
            this.checkForm.duijiang = false
          }
          if (this.checkForm.car_hotline == 1) {
            this.checkForm.car_hotline = true
            this.isShowNumber = true
          } else {
            this.checkForm.car_hotline = false
            this.isShowNumber = false
          }
          if (this.checkForm.carnumber_show == 1) {
            this.checkForm.carnumber_show = true
          } else {
            this.checkForm.carnumber_show = false
          }
          if (this.checkForm.payments_offline == 1) {
            this.checkForm.payments_offline = true
          } else {
            this.checkForm.payments_offline = false
          }
          if (this.checkForm.payment_online == 1) {
            this.checkForm.payment_online = true
          } else {
            this.checkForm.payment_online = false
          }
          if (this.checkForm.payment_wxpay == 1) {
            this.checkForm.payment_wxpay = true
          } else {
            this.checkForm.payment_wxpay = false
          }
          if (this.checkForm.payment_alipay == 1) {
            this.checkForm.payment_alipay = true
          } else {
            this.checkForm.payment_alipay = false
          }
          if (this.checkForm.ordernum == 1) {
            this.checkForm.ordernum = true
          } else {
            this.checkForm.ordernum = false
          }
          if (this.checkForm.quyu_ordernum == 1) {
            this.checkForm.quyu_ordernum = true
          } else {
            this.checkForm.quyu_ordernum = false
          }
          
          if (this.checkForm.is_phone == 1) {
            this.checkForm.is_phone = true
          } else {
            this.checkForm.is_phone = false
          }
          
          
          if (this.checkForm.booking_taxi == 1) {
            this.checkForm.booking_taxi = true
          } else {
            this.checkForm.booking_taxi = false
          }
          if (this.checkForm.check_phone == 1) {
            this.checkForm.check_phone = true
          } else {
            this.checkForm.check_phone = false
          }
          if (this.checkForm.is_old == 1) {
            this.checkForm.is_old = true
          } else {
            this.checkForm.is_old = false
          }
          if (this.checkForm.payment_childrenpay == 1) {
            this.checkForm.payment_childrenpay = true
          } else {
            this.checkForm.payment_childrenpay = false
          }
          if (this.checkForm.face_authentication == 1) {
            this.checkForm.face_authentication = true
          } else {
            this.checkForm.face_authentication = false
          }

           if (this.checkForm.nearby_orders == 1) {
            this.checkForm.nearby_orders = true
          } else {
            this.checkForm.nearby_orders = false
          }

           if (this.checkForm.navigation_distance == 1) {
            this.checkForm.navigation_distance = true
          } else {
            this.checkForm.navigation_distance = false
          }

          if (this.checkForm.payment_unionpay == 1) {
            this.checkForm.payment_unionpay = true
          } else {
            this.checkForm.payment_unionpay = false
          }
        } else {
          this.dialogTableVisibleEdit = false
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
    },

    open_setting() {
      const loading = this.$loading({
        lock: true
      })
      if (this.checkForm.chuzu == true) {
        this.checkForm.chuzu = 1
      } else {

        this.checkForm.chuzu = 0
      }
      if (this.checkForm.pinche == true) {
        this.checkForm.pinche = 1
      } else {
        this.checkForm.pinche = 0
      }
      if (this.checkForm.daijia == true) {
        this.checkForm.daijia = 1
      } else {
        this.checkForm.daijia = 0
      }
      if (this.checkForm.paotui == true) {
        this.checkForm.paotui = 1
      } else {
        this.checkForm.paotui = 0
      }
      if (this.checkForm.chaoshi == true) {
        this.checkForm.chaoshi = 1
      } else {
        this.checkForm.chaoshi = 0
      }
      if (this.checkForm.jiaocan == true) {
        this.checkForm.jiaocan = 1
      } else {
        this.checkForm.jiaocan = 0
      }
      if (this.checkForm.xianquan == true) {
        this.checkForm.xianquan = 1
      } else {
        this.checkForm.xianquan = 0
      }
      if (this.checkForm.toutiao == true) {
        this.checkForm.toutiao = 1
      } else {
        this.checkForm.toutiao = 0
      }
      if (this.checkForm.jingdian == true) {
        this.checkForm.jingdian = 1
      } else {
        this.checkForm.jingdian = 0
      }
      if (this.checkForm.yuyin == true) {
        this.checkForm.yuyin = 1
      } else {
        this.checkForm.yuyin = 0
      }
      if (this.checkForm.duijiang == true) {
        this.checkForm.duijiang = 1
      } else {
        this.checkForm.duijiang = 0
      }
      if (this.checkForm.car_hotline == true) {
        this.checkForm.car_hotline = 1
      } else {
        this.checkForm.car_hotline = 0
      }
      if (this.checkForm.carnumber_show == true) {
        this.checkForm.carnumber_show = 1
      } else {
        this.checkForm.carnumber_show = 0
      }
      if (this.checkForm.payment_wxpay == true) {
        this.checkForm.payment_wxpay = 1
      } else {
        this.checkForm.payment_wxpay = 0
      }
      if (this.checkForm.payment_alipay == true) {
        this.checkForm.payment_alipay = 1
      } else {
        this.checkForm.payment_alipay = 0
      }
      if (this.checkForm.payments_offline == true) {
        this.checkForm.payments_offline = 1
      } else {
        this.checkForm.payments_offline = 0
      }
      if (this.checkForm.ordernum == true) {
        this.checkForm.ordernum = 1
      } else {
        this.checkForm.ordernum = 0
      }
      
      if (this.checkForm.is_phone == true) {
        this.checkForm.is_phone = 1
      } else {
        this.checkForm.is_phone = 0
      }

      if (this.checkForm.nearby_orders == true) {
        this.checkForm.nearby_orders = 1
      } else {
        this.checkForm.nearby_orders = 0
      }

        if (this.checkForm.navigation_distance == true) {
        this.checkForm.navigation_distance = 1
      } else {
        this.checkForm.navigation_distance = 0
      }
      if (this.checkForm.face_authentication == true) {
        this.checkForm.face_authentication = 1
      } else {
        this.checkForm.face_authentication = 0
      }
      if (this.checkForm.check_phone == true) {
        this.checkForm.check_phone = 1
      } else {
        this.checkForm.check_phone = 0
      }
      
      if (this.checkForm.quyu_ordernum == true) {
        this.checkForm.quyu_ordernum = 1
      } else {
        this.checkForm.quyu_ordernum = 0
      }
      this.$http.post('/city/setting_status', this.checkForm).then(res => {
        loading.close()
        this.dialogTableVisibleEdit = false
        if (res.data.code === 0) {
          this.showEdit = false
          this.$message({
            type: 'success',
            message: res.data.msg
          })
          // this.$refs.table.reload();
        } else {
          this.dialogTableVisibleEdit = false
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        loading.close()
        this.$message.error(e.message)
      })

    },

  }
}
</script>

<style scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

/deep/ .el-collapse-item__header {
  padding: 0 20px;
  position: relative
}

/deep/ .el-collapse-item__arrow {
  margin: 0;
  position: absolute;
  left: 0
}

table,
table tr th,
table tr td {
  border: 1px solid #EAEAEA;
}

.cityContentBox {
  border: 1px solid #EAEAEA;
  border-bottom: 0
}

.cityContentBox1 td {
  padding: 20px 10px
}

.cityHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  margin-left: 20px
}

.cityHeader1 {
  display: flex;
  margin: 10px;
  margin-left: 20px;
  width: 100%;
  text-align: center
}

.cityHeader1 td {
  flex: 1;
  text-align: left;
  padding-left: 10px
}

.cityContent {
  display: flex;
  background: #F2F2F2;
  padding: 15px 10px;
}

.bgwhite {
  background: #fff;
  height: 60px;
  line-height: 60px;
  display: flex;
}

.bgwhite1 {
  background: #fff;
  height: 60px;
  line-height: 60px;
  display: flex;
}

.cityContent div {
  flex: 1;
  margin-left: 10px
}

.bgwhite div {
  flex: 1;
  padding-left: 10px;
  border-bottom: 1px solid #EAEAEA;
  border-right: 1px solid #EAEAEA;
  box-sizing: border-box;
}

.bgwhite1 div {
  width: 20%;
  padding-left: 10px;
  border-bottom: 1px solid #EAEAEA;
  border-right: 1px solid #EAEAEA;
  box-sizing: border-box;
}

.cityStatus {
  color: #23B597
}

.m-10 {
  margin: 10px
}

.m-20 {
  margin: 10px 20px
}

.layui-table {
  position: relative;
  /*padding: 9px 15px;*/
  min-height: 20px;
  line-height: 20px;
  font-size: 14px;
  width: 100%;
  /*border:1px solid #e6e6e6;*/
  /*border-bottom:0;*/
  background: #f2f2f2;
  margin: 0;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;

}

.customer-table th {
  border: none;
}

.colorGreen {
  color: #1ab394
}

.btn_green {
  width: 50px;
  height: 30px;
  background: #23B597;
  color: #fff;
  border-radius: 4px;
}

.btn_green:hover {
  width: 50px;
  height: 30px;
  background: #2caa91;
  color: #fff;
  border-radius: 4px;
}

.btn_red {
  width: 50px;
  height: 30px;
  background: red;
  color: #fff;
  border-radius: 4px;
}

.btn_red:hover {
  width: 50px;
  height: 30px;
  background: red;
  color: #fff;
  border-radius: 4px;
}

/deep/ .el-dialog {
  margin-top: 60px !important;
}

.item_switch {
  margin-right: 10px;
  width: 43%;
  float: left
}

.item_switch .el-switch {
  margin-left: 20%;
}

.item_switch:nth-child(2n) .el-switch {
  margin-left: 10%;
}

.title-box {
  position: relative;
}

.title-item {
  position: absolute;
  left: -100px;
  top: 20px;
  font-size: 12px;
  color: #409EFF;
  cursor: pointer;
}
</style>
